<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"><span v-if="recordId && recordId > 0">Update</span><span v-else>Add</span> {{module_name}}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Name</label>
                                        <InputText v-model="form.name" id="name" type="text" placeholder="Name"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="name" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Email</label>
                                        <InputText v-model="form.email" id="email" type="text" placeholder="Email"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="email" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Phone</label>
                                        <InputText v-model="form.phone" id="phone" type="text" placeholder="Phone"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="phone" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Father Name</label>
                                        <InputText v-model="form.father_name" id="father_name" type="text" placeholder="Father Name"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="father_name" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Nationality</label>
                                        <InputText v-model="form.nationality" id="nationality" type="text" placeholder="Nationality"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="nationality" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Registration No</label>
                                        <InputText v-model="form.nums_reg_no" id="nums_reg_no" type="nums_reg_no" placeholder="Registration No"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="nums_reg_no" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>CNIC</label>
                                        <InputText v-model="form.cnic" id="cnic" type="text" placeholder="CNIC"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="cnic" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Mailing Address</label>
                                        <Textarea v-model="form.mailing_address" name="mailing_address" id="mailing_address"  type="text" placeholder="Mailing Address" class="full-width"  rows="7"/>
                                        <HasError class="p-error" :form="form" field="mailing_address" /> 
                                    </div>

                                </div>
                                
                                
                            </div>  
                            

                        </div> 
                        
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <ConfirmDialog group="dialog" />
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import Dropdown from 'primevue/dropdown';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    name: '', 
                    email:'',
                    phone: '', 
                    father_name: '', 
                    nationality:'',
                    nums_reg_no: '', 
                    cnic:'',
                    mailing_address: '', 
                }),   
              
                module_name:'Application Form',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
           
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId;
            
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            this.getAllClasses();
		},
		methods: { 
            getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                    // this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },  
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                this.form.id = this.recId;
                
                var url = Vthis.$baseurl+'api/update-nums-application';
                await this.form.post(url)
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            
           
            
		},
        watch : {
             
        }
}
</script>
 
