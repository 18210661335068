<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3>{{ module_name }}</h3>
                    </template>
                   
                </Toolbar>
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start>
                        <Button  v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords(selectedAddAppNum)" /> 
                        <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <!-- <Button  v-tooltip.top="'Download Forms'" icon="pi pi-cloud-download" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" :label="showBtnLabels ? 'Download Forms' : ''" @click="downloadSelectedForm($event)" /> -->
                        <Dropdown @change="selectAddAppNum($event)" v-model="selectedCity" :options="AddAppNums" optionValue="id" optionLabel="name" placeholder="Select a NUMS Registration" class="w-full md:w-21rem ml-2" />
          
                    </template> 
				</Toolbar> 
                
                <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','added_by','modified_by','date_added','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" 
                    :scrollable="true" scrollHeight="400px">
                   
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                        :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                        :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                      
                       
                        <template  #body="{data}">
                           
                            <span v-if="col.field == 'download'"><Button @click="getPDF(data.id,data.student_id,data.name,formatDate(data.created_at))" icon="pi pi-cloud-download" /></span>
                            <span v-if="col.field == 'id'">{{ data.id }}</span>
                            <span v-if="col.field == 'student_id'">{{ zeroPad(data.student_id, 5) }}</span>
                            <img  v-if="col.field == 'name' && data.profile_pic" :alt="data.name" :src="studentFiles+'students/'+data.std_id+'/'+data.profile_pic" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'name' && !data.profile_pic" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'name'" @click="selectedRecordId = data.id;selectedData = JSON.stringify(data);">{{ data.name }}</span>
                            <span v-if="col.field == 'email'">{{ data.email }}</span>
                            <span v-if="col.field == 'phone'">{{ data.phone }}</span>
                            <span v-if="col.field == 'program'">{{ data.program }}</span>
                            <span v-if="col.field == 'prof_year'">{{ formatDate(data.prof_year) }}</span>
                            <span v-if="col.field == 'father_name'">{{ data.father_name }}</span>
                            <span v-if="col.field == 'institute'">{{ data.institute }}</span>
                            <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                            <span v-if="col.field == 'nationality'">{{ data.nationality }}</span>
                            <span v-if="col.field == 'cnic'">{{ data.cnic }}</span>
                            <span v-if="col.field == 'mailing_address'">{{ data.mailing_address }}</span>
                            <span v-if="col.field == 'annual_or_supply'">{{ data.annual_or_supply }}</span>
                            <span v-if="col.field == 'created_at'">{{ formatDate(data.created_at) }}</span>
                        
                            <span v-if="col.field == 'action'"  @click="selectedRecordId = data.id;selectedData = JSON.stringify(data);">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span> 
                            
                        </template>
                       
                         <template #filter="{filterModel}">
                            <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span>   
                           
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>    
                    </Column>   
				</DataTable>

            </div>
        </div>
        <Toast />
        <ApplicationForm v-on:formClose="closeForm" v-if="showForm" :selectedData="selectedData" :recordId="selectedRecordId" :showForm="true" />
     
    </div>
</template>
<script>
import ApplicationForm from './Modals/ApplicationForm.vue';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Applications',
            actions: [],
            showHelp: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: false,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            showView: false,
            selectedRecordId: 0,
            saveAndClose: false,
            selectedData: '',
            isDisabled: true,
            AddAppNums: [],
            selectedAddAppNum: null,
            selectedCity: null,
        };
    },
    components: {
        ApplicationForm,
    },
    created() {
        this.actions.push({
            label: 'Edit',
            icon: 'pi pi-refresh',
            command: () => {
                this.getClickedRecord('update');
            },
        });

        this.initFilters();
        this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'download', header: 'Download'},
                {field: 'id', header: 'Application Id'},
                {field: 'student_id', header: 'Student Id'},    
                {field: 'name', header: 'Name'}, 
                
                
                {field: 'email', header: 'Email'}, 
                {field: 'phone', header: 'Phone'}, 
                {field: 'program', header: 'Program'}, 
                {field: 'prof_year', header: 'Prof. Year'}, 
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'institute', header: 'Institute'}, 

                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'nationality', header: 'Nationality'},    
                {field: 'cnic', header: 'CNIC'},    
                {field: 'mailing_address', header: 'Mailing Address'},    
                {field: 'annual_or_supply', header: 'Annual/Supply'},    
                {field: 'created_at', header: 'Date Added'},    
               
            ];
            this.selectedColumns = this.columns;
    },
    computed: {},
    mounted() {
        this.getAddAppNums();
    },
    methods: {
        selectAddAppNum(event) {
            this.isDisabled = false;
            this.getRecords(event.value);
        },
        addNewRecord() {
            this.selectedData = null;
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        getClickedRecord(action) {
            if (action == 'view') {
                this.showView = true;
            } else if (action == 'update') {
                this.showForm = true;
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.selectedData = event.currentTarget.getAttribute('selectedData');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        closeForm() {
            // console.log(this.selectedSubject)
            this.getRecords(this.selectedAddAppNum);
            this.showForm = false;
            this.showView = false;
        },
        getRecords(add_app_nums = null) {
            var Vthis = this;
            this.loading = true;
            this.selectedAddAppNum = add_app_nums;
            this.axios
                .post('api/getAllAdmissionApplications', { add_app_nums: add_app_nums })
                .then((response) => {
                    this.records = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        getAddAppNums() {
            var Vthis = this;

            this.axios
                .post('api/get-admission-application-nums')
                .then((response) => {
                    this.AddAppNums = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },

        getPDF(id,stu_id,name,date) {
                this.loading = true;
                this.axios.get('api/get-pdf', {
                    params: { 
                        status: this.getStatus,
                        id: id,
                    },
                    responseType: 'blob',
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    // Create a blob from the response data
                    const blob = new Blob([response.data], { type: 'application/pdf' });

                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "NUMS_Form_"+name+"_"+stu_id+"_"+date+'.pdf'; // Set the desired file name

                    // Trigger the click event on the link to start the download
                    link.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                    this.loading = false;
                });
            },
            
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
                this.getStatus=0;
                this.getRecords();  
			}, 
            isRTL() {
				return this.$appState.RTL;
			},
            zeroPad(value, length) {
                // Convert the value to a string
                let strValue = value.toString();

                // Calculate the number of zeros to add
                let zerosToAdd = length - strValue.length;

                // Add the zeros to the beginning of the string
                let paddedValue = '0'.repeat(zerosToAdd) + strValue;

                return paddedValue;
            },
    },
    watch: {},
};
</script>

 

<style scoped>
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
